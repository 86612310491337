<template>
    <transition>
        <component :is="current" @switch="switchPage"></component>
    </transition>
</template>

<script>
    import Detail from "./detail.vue";
    import List from "./list.vue";
    export default {
        data() {
            return {
                current: "List",

                wasteTypes: [],
                dUnitList: [],
                hUnitList: [],
                handleTypes: [],
                countList: [],

                options: null
            };
        },
        methods: {
            switchPage(options) {
                if (this.current === "List") {
                    this.current = "Detail";
                } else {
                    this.current = "List";
                }

                this.options = options;
            },
            initCount() {
                this.$http.post("/deal/countList").then(res => {
                    this.countList = res.detail;
                });
            },
            initDicts() {
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.dUnitList = res.detail;
                    });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "2"
                    })
                    .then(res => {
                        this.hUnitList = res.detail;
                    });
                this.$http.post("/dict/getDictValuesByDictId", "dictdelway").then(res => {
                    this.handleTypes = res.detail;
                });
            }
        },
        created() {
            this.initCount();
            this.initDicts();
        },
        components: {
            Detail,
            List
        }
    };
</script>
<style></style>
