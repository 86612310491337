<template>
    <div>
        <div>
            <el-page-header @back="backMain" :content="'处置中心 > ' + title"></el-page-header>
            <el-form :rules="rules" :model="model" ref="handleForm" class="detail_form" size="small" label-position="right" label-width="130px">
                <div class="form_item">
                    <div class="head_title">基本信息</div>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="单据编号">
                                <el-input v-if="!register" :value="model.receiptNo" disabled></el-input>
                                <span v-else>{{ model.receiptNo }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="处置出厂日期" prop="dealT">
                                <el-date-picker v-if="!register" type="date" value-format="yyyy-MM-dd" v-model="model.dealT"></el-date-picker>
                                <span v-else>{{ model.dealT }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="出库分拣中心" prop="unitId">
                                <el-select v-if="!register" v-model="model.unitId" @change="changeUnit">
                                    <el-option v-for="u in dUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                                </el-select>
                                <span v-else>{{ formatUnit(model.unitId) }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="处置单位" prop="dUnitId">
                                <el-select v-if="!register" v-model="model.dUnitId">
                                    <el-option v-for="u in hUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                                </el-select>
                                <!-- <el-autocomplete
                                    v-if="!register"
                                    :value="formatHUnit(model.dUnitId)"
                                    :fetch-suggestions="querySearchAsync"
                                    placeholder="请输入处置单位名称"
                                    value-key="cname"
                                    @select="handleSelect"
                                ></el-autocomplete> -->
                                <span v-else>{{ formatHUnit(model.dUnitId) }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="固废类别" prop="wasteT">
                                <el-select v-if="!register" v-model="model.wasteT">
                                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                                </el-select>
                                <span v-else>{{ formatWast(model.wasteT) }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车牌号" prop="plateNum">
                                <!-- <el-input v-if="!register" :value="model.plateNum" @focus="showVehicleDialog"></el-input> -->
                                <el-autocomplete v-if="!register" v-model="model.plateNum" :fetch-suggestions="fetchSuggestions('handleCenterDetailVNoSuggestions')" @select="handleSelected('plateNum')" />
                                <span v-else>{{ model.plateNum }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="司机" prop="driverNa">
                                <!-- <el-input v-if="!register" :value="model.driverNa" @focus="showDriverDialog"></el-input> -->
                                <el-autocomplete v-if="!register" v-model="model.driverNa" :fetch-suggestions="fetchSuggestions('handleCenterDetailDNaSuggestions')" @select="handleSelected('driverNa')" />
                                <span v-else>{{ model.driverNa }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="GPS编号">
                                <el-input v-if="!register" v-model="model.gpsNo"></el-input>
                                <span v-else>{{ model.gpsNo }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="备注">
                                <el-input v-if="!register" v-model="model.remark"></el-input>
                                <span v-else>{{ model.remark }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" v-if="false">
                            <el-form-item label="行驶证">
                                <com-img :url="vehicleLicense"></com-img>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="false">
                            <el-form-item label="驾驶证">
                                <com-img :url="driverLicense"></com-img>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-if="!register" v-model="model.phone"></el-input>
                                <span v-else>{{ model.phone }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="form_item">
                    <div class="head_title">出厂过磅信息</div>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="出厂过磅毛重" prop="grossW">
                                <el-input v-if="!register" v-model="model.grossW">
                                    <template slot="append">{{ typeUnit }}</template>
                                </el-input>
                                <span v-else>{{ model.grossW }}{{ typeUnit }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="出厂过磅皮重" prop="tareW">
                                <el-input v-if="!register" v-model="model.tareW">
                                    <template slot="append">{{ typeUnit }}</template>
                                </el-input>
                                <span v-else>{{ model.tareW }}{{ typeUnit }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="出厂过磅净重">{{ formatSuttle(model.grossW, model.tareW) }}{{ typeUnit }}</el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="装运时间">
                                <el-date-picker v-if="!register" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="model.loadT"></el-date-picker>
                                <span v-else>{{ model.loadT }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="毛重过磅时间">
                                <el-date-picker v-if="!register" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="model.grossWT"></el-date-picker>
                                <span v-else>{{ model.grossWT }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="皮重过磅时间">
                                <el-date-picker v-if="!register" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="model.tareWT"></el-date-picker>
                                <span v-else>{{ model.tareWT }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="装运照片">
                                <el-upload
                                    v-if="!register"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleLoadUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <com-img v-if="model.loadUrl" :url="model.loadUrl"></com-img>
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                    <com-img v-else :url="model.loadUrl"></com-img>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="毛重过磅照片">
                                <el-upload
                                    v-if="!register"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleGrossWUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <com-img v-if="model.grossWUrl" :url="model.grossWUrl"></com-img>
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <com-img v-else :url="model.grossWUrl"></com-img>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="皮重过磅照片">
                                <el-upload
                                    v-if="!register"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleTareWUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <com-img v-if="model.tareWUrl" :url="model.tareWUrl"></com-img>
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <com-img v-else :url="model.tareWUrl"></com-img>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
            <el-form v-if="register" :rules="rules" :model="reModel" ref="reHandleForm" class="detail_form" size="small" label-position="right" label-width="130px">
                <div class="form_item">
                    <div class="head_title">处置过磅信息</div>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="处置过磅毛重" prop="dGrossW">
                                <el-input v-model="reModel.dGrossW" v-if="!view">
                                    <template slot="append">{{ typeUnit }}</template>
                                </el-input>
                                <span v-else>{{ reModel.dGrossW }}{{ typeUnit }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="处置过磅皮重" prop="dTareW">
                                <el-input v-model="reModel.dTareW" v-if="!view">
                                    <template slot="append">{{ typeUnit }}</template>
                                </el-input>
                                <span v-else>{{ reModel.dTareW }}{{ typeUnit }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="处置过磅净重">{{ formatSuttle(reModel.dGrossW, reModel.dTareW) }}{{ typeUnit }}</el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="处置方式" prop="delway">
                                <el-select v-model="reModel.delway" v-if="!view">
                                    <el-option v-for="t in handleTypes" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                                </el-select>
                                <span v-else>{{ reModel.delway }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="毛重过磅时间">
                                <el-date-picker v-if="!view" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="reModel.dGrossWT"></el-date-picker>
                                <span v-else>{{ reModel.dGrossWT }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="皮重过磅时间">
                                <el-date-picker v-if="!view" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="reModel.dTareWT"></el-date-picker>
                                <span v-else>{{ reModel.dTareWT }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="回执单">
                                <el-upload
                                    v-if="!view"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleReceiptUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <com-img v-if="reModel.receiptUrl" :url="reModel.receiptUrl"></com-img>
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <com-img v-else :url="reModel.receiptUrl"></com-img>
                                <!-- <img class="avatar" v-else :src="reModel.receiptUrl ? reModel.receiptUrl : Avatar" /> -->
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="毛重过磅照片">
                                <el-upload
                                    v-if="!view"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleDGrossWUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <com-img v-if="reModel.dGrossWUrl" :url="reModel.dGrossWUrl"></com-img>
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <com-img v-else :url="reModel.dGrossWUrl"></com-img>
                                <!-- <img class="avatar" v-else :src="reModel.dGrossWUrl" /> -->
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="皮重过磅照片">
                                <el-upload
                                    v-if="!view"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleDTareWUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <com-img v-if="reModel.dTareWUrl" :url="reModel.dTareWUrl"></com-img>
                                    <!-- <img v-if="reModel.dTareWUrl" :src="reModel.dTareWUrl" class="avatar" /> -->
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <com-img v-else :url="reModel.dTareWUrl"></com-img>
                                <!-- <img class="avatar" v-else :src="reModel.dTareWUrl ? reModel.dTareWUrl : Avatar" /> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
            <div class="form_handle" v-if="!view">
                <el-button type="primary" size="small" v-if="register" @click="reRegister">回执登记</el-button>
                <el-button v-if="edit || add" type="primary" size="small" @click="makeSure">确定</el-button>
                <el-button size="small" @click="backMain">取消</el-button>
            </div>
        </div>

        <select-vehicle ref="vehicle" @select="selectVehicle"></select-vehicle>
        <select-driver mode="radio" ref="driver" @select="selectDriver"></select-driver>
    </div>
</template>

<script>
    import SelectVehicle from "@/components/pages/admin/selectVehicle/SelectVehicle.vue";
    import SelectDriver from "@/components/pages/admin/selectDriver/SelectDriver.vue";
    import Avatar from "@/assets/common/default.png";
    export default {
        methods: {
            querySearchAsync(queryString, cb) {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: queryString,
                        unitType: "2"
                    })
                    .then(res => {
                        cb(res.detail);
                    });
            },
            handleSelect(row) {
                this.model.dUnitId = row.unitId;
            },
            backMain() {
                this.$emit("switch");
            },
            makeSure() {
                this.$refs.handleForm.validate(valide => {
                    if (valide) {
                        let url = "/deal/add";
                        if (this.model.dealId) {
                            url = "/deal/update";
                        }
                        this.$http.post(url, this.model).then(res => {
                            const DNaSuggestions = JSON.parse((localStorage.getItem('handleCenterDetailDNaSuggestions') || JSON.stringify([]))).concat(JSON.stringify({ value: this.model.driverNa }));
                            const VNoSuggestions = JSON.parse((localStorage.getItem('handleCenterDetailVNoSuggestions') || JSON.stringify([]))).concat(JSON.stringify({ value: this.model.plateNum }));
                            localStorage.setItem('handleCenterDetailDNaSuggestions', JSON.stringify(Array.from(new Set(DNaSuggestions))));
                            localStorage.setItem('handleCenterDetailVNoSuggestions', JSON.stringify(Array.from(new Set(VNoSuggestions))));
                            this.$message.success({
                                message: res.msg
                            });
                            this.backMain();
                        });
                    }
                });
            },
            reRegister() {
                this.$refs.reHandleForm.validate(valide => {
                    if (valide) {
                        this.$http.post("/deal/receipt", this.reModel).then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.backMain();
                        });
                    }
                });
            },
            changeUnit(key) {
                const unit = this.dUnitList.find(v => v.unitId === key);
                this.model.orgId = unit.orgId;
                this.model.mOrgId = unit.morgId;
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            handleLoadUrlAvatarSuccess({ detail }) {
                this.model.loadUrl = detail;
            },
            handleGrossWUrlAvatarSuccess({ detail }) {
                this.model.grossWUrl = detail;
            },
            handleTareWUrlAvatarSuccess({ detail }) {
                this.model.tareWUrl = detail;
            },
            handleDGrossWUrlAvatarSuccess({ detail }) {
                this.reModel.dGrossWUrl = detail;
            },
            handleDTareWUrlAvatarSuccess({ detail }) {
                this.reModel.dTareWUrl = detail;
            },
            handleReceiptUrlAvatarSuccess({ detail }) {
                this.reModel.receiptUrl = detail;
            },
            generateReceiptNo() {
                return "CZ" + this.$util.parseTime(new Date(), "yyyyMMddhhmmss");
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            showVehicleDialog() {
                if (!this.model.dealT) {
                    this.$message({
                        message:'请先选择出厂日期',
                        type:'warning'
                    })
                    return
                }
                this.$refs.vehicle.init({pTime:this.model.dealT});
            },
            showDriverDialog() {
                this.$refs.driver.init();
            },
            selectVehicle(row) {
                this.model.vehicleId = row.vehicleId;
                this.model.plateNum = row.plateNum;
                this.vehicleLicense = row.regUrl;
            },
            selectDriver(row) {
                this.model.driverId = row.driverId;
                this.model.driverNa = row.cName;
                this.driverLicense = row.dlFUrl;
            },
            formatSuttle(m, p) {
                if (isNaN(m) || isNaN(p)) {
                    return 0;
                } else {
                    return m - p;
                }
            },
            formatUnit(key) {
                const u = this.dUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatHUnit(key) {
                const u = this.hUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatWast(id) {
                const w = this.wasteTypes.find(v => v.wasteTId === id);
                if (w) {
                    return w.cName;
                }
                return "-";
            },
            createFilter(queryString) {
                return (item) => {
                    return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            fetchSuggestions(itemKey) {
                return (queryString, cb) => {
                    const item = localStorage.getItem(itemKey) || JSON.stringify([]);
                    const suggestions = JSON.parse(item);
                    for (let i = 0; i < suggestions.length; i++) {
                        suggestions[i] = JSON.parse(suggestions[i]);
                    }
                    const res = queryString
                        ? suggestions.filter(this.createFilter(queryString))
                        : suggestions;
                    cb(res);
                }
            },
            handleSelected(key) {
                return (item) => {
                    this.model[key] = item.value
                }
            }
        },
        mounted() {
            const options = this.$parent.options;
            if (options) {
                this.edit = options.edit;
                this.register = options.register;
                this.view = options.view;
                this.model = options;

                if (this.edit) {
                    this.title = "编辑";
                }
                if (this.register) {
                    this.title = "回执登记";
                    this.reModel.dealId = options.dealId;
                }
                if (this.view) {
                    this.title = "查看";
                    this.reModel = {
                        dGrossW: options.dGrossW,
                        dGrossWT: options.dGrossWT,
                        dGrossWUrl: options.dGrossWUrl,
                        dTareW: options.dTareW,
                        dTareWT: options.dTareWT,
                        dTareWUrl: options.dTareWUrl,
                        receiptUrl: options.receiptUrl,
                        delway: options.delway
                    };
                    this.$http
                        .post('/base/vehicle/queryInfoByVehicleId', options.vehicleId)
                        .then(res => {
                            this.vehicleLicense = res.detail.vehicleAttach.regUrl;
                        })
                    this.$http
                        .post('/base/driver/queryDriverAttachByDriverId', options.driverId)
                        .then(res => {
                            this.driverLicense = res.detail.dlFUrl;
                        })
                }
            } else {
                this.model.receiptNo = this.generateReceiptNo();
                this.add = true;
            }
            this.setUploadHeader();
        },
        computed: {
            dUnitList() {
                console.log(this.$parent.dUnitList, 'dd')
                return this.$parent.dUnitList;
            },
            hUnitList() {
                return this.$parent.hUnitList;
            },
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            handleTypes() {
                return this.$parent.handleTypes;
            },
            typeUnit() {
                if (this.wasteTypes && this.model.wasteT) {
                    const type = this.wasteTypes.find(v => v.wasteTId === this.model.wasteT);
                    return type.unit;
                } else {
                    return "kg";
                }
            }
        },
        data() {
            return {
                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {},

                title: "新增",
                orgName: "",
                // typeUnit: "kg",
                model: {
                    receiptNo: "",
                    dealT: this.$util.parseTime(new Date(), "yyyy-MM-dd"),
                    // driverId: "",
                    // vehicleId: "",
                    unitId: "",
                    dUnitId: "",
                    wasteT: "",
                    plateNum: "",
                    driverNa: "",
                    phone: '',

                    gpsNo: "",
                    remark: "",
                    grossW: "",
                    tareW: "",
                    loadT: "",
                    grossWT: "",
                    tareWT: "",
                    loadUrl: "",
                    grossWUrl: "",
                    tareWUrl: ""
                },

                reModel: {
                    dGrossW: "",
                    dGrossWT: "",
                    dTareWUrl: "",
                    dTareW: "",
                    dTareWT: "",
                    dGrossWUrl: "",

                    delway: "",
                    receiptUrl: ""
                },

                rules: {
                    dealT: [{ required: true, message: "处置出厂日期不为空", tirgger: "change" }],
                    unitId: [{ required: true, message: "出库分拣中心不为空", tirgger: "change" }],
                    dUnitId: [{ required: true, message: "处置单位不为空", tirgger: "change" }],
                    wasteT: [{ required: true, message: "固废类别不为空", tirgger: "change" }],
                    //vehicleId: [{ required: true, message: "车牌号不为空", tirgger: "change" }],
                    //driverId: [{ required: true, message: "司机不为空", tirgger: "change" }],
                    plateNum: [{ required: true, message: "车牌号不为空", tirgger: "change" }],
                    driverNa: [{ required: true, message: "司机不为空", tirgger: "change" }],

                    grossW: [{ required: true, message: "出厂过磅毛重不为空", tirgger: "blur" }],
                    tareW: [{ required: true, message: "出厂过磅皮重不为空", tirgger: "blur" }],

                    dGrossW: [{ required: true, message: "处置出厂过磅毛重不为空", tirgger: "blur" }],
                    dTareW: [{ required: true, message: "处置出厂过磅皮重不为空", tirgger: "blur" }],
                    delway: [{ required: true, message: "处置方式不为空", tirgger: "change" }],
                    phone: [{ required: true, message: "联系电话不为空", tirgger: "blur" }]
                },

                register: false,
                edit: false,
                add: false,
                view: false,

                vehicleLicense: "",
                driverLicense: "",

                // 0新增 1编辑 2查看 3登记回执
                status: 0,
                Avatar
            };
        },
        components: {
            SelectDriver,
            SelectVehicle
        }
    };
</script>
<style lang="scss" scoped>
    .avatar {
        width: 100px;
        height: 100px;
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }

    .form_handle {
        text-align: center;
    }
</style>
