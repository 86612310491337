<template>
    <div>
        <el-radio-group size="mini" v-model="current" class="page_header" @change="getList">
            <el-radio-button v-for="r in countList" :key="r.status" :label="r.status">{{ r.statusStr }}（{{ r.count }}）</el-radio-button>
        </el-radio-group>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="单据编号" prop="receiptNo">
                <el-input v-model="form.receiptNo"></el-input>
            </jy-query-item>
            <jy-query-item label="出库分拣中心" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in dUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteT">
                <el-select v-model="form.wasteT">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="处置单位" prop="dUnitId">
                <el-select v-model="form.dUnitId">
                    <el-option v-for="u in hUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="处置方式" prop="delway" v-if="current === '1'">
                <el-select v-model="form.delway">
                    <el-option v-for="t in handleTypes" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车牌号" prop="plateNum">
                <el-input v-model="form.plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="司机" prop="driverNa">
                <el-input v-model="form.driverNa"></el-input>
            </jy-query-item>
            <jy-query-item label="GPS编号" prop="gpsNo">
                <el-input v-model="form.gpsNo"></el-input>
            </jy-query-item>
            <jy-query-item label="制单人" prop="cUserNa">
                <el-input v-model="form.cUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="回执登记人" prop="rUserNa" v-if="current === '1'">
                <el-input v-model="form.rUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="处置出厂日期" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesHandleCenterList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesHandleCenterReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesHandleCenterInsert')">新增</el-button>
                    <export-excel v-if="btnexist('wastesHandleCenterExport') && false" url="/deal/export" fileName="处置中心" fileType=".xls" size="small"></export-excel>
                    <el-button size="small" type="primary" @click="showDefineTable">自定义列表</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="tableData">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('receiptNo')" label="单据编号" prop="receiptNo" min-width="170"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('dealT')" label="处置出厂日期" prop="dealT" min-width="160"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('unitId')" label="出库分拣中心" min-width="150">
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column v-if="columnProps.includes('wasteT')" label="固废类别"  min-width="120">
                    <template v-slot="scope">{{ formatWast(scope.row.wasteT) }}</template>
                </jy-table-column>
                <jy-table-column v-if="columnProps.includes('dUnitId')" label="处置单位"  min-width="140">
                    <template v-slot="scope">{{ formatHUnit(scope.row.dUnitId) }}</template>
                </jy-table-column>
                <jy-table-column v-if="columnProps.includes('delway') && current === '1'" label="处置方式" prop="delway" min-width="120"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('grossW')" label="出厂毛重" prop="grossW" key="grossW"  min-width="120">
                    <template v-slot="scope">
                        <span>{{ scope.row.grossW }}{{ scope.row.unit }}</span>
                    </template>
                </jy-table-column>
                <jy-table-column v-if="columnProps.includes('tareW')" label="出厂皮重" prop="tareW" key="tareW" min-width="120">
                    <template v-slot="scope">
                        <span>{{ scope.row.tareW }}{{ scope.row.unit }}</span>
                    </template>
                </jy-table-column>
                <jy-table-column v-if="columnProps.includes('suttleW')" label="出厂净重" prop="suttleW" key="suttleW" min-width="120">
                    <template v-slot="scope">{{ formatSuttle(scope.row.grossW, scope.row.tareW) }}{{ scope.row.unit }}</template>
                </jy-table-column>
                <jy-table-column label="处置过磅净重" v-if="columnProps.includes('sum') && current === '1'"  min-width="150">
                    <template v-slot="scope">{{ formatSuttle(scope.row.dGrossW, scope.row.dTareW) }}{{ scope.row.unit }}</template>
                </jy-table-column>
                <jy-table-column label="偏差值" tip="偏差值 = (出厂净重 - 处置过磅净重) / 处置过磅净重" v-if="columnProps.includes('deviation') && current === '1'" min-width="120">
                    <template v-slot="scope">{{ formatDeviationValue(scope.row.grossW, scope.row.tareW, scope.row.dGrossW, scope.row.dTareW) }}</template>
                </jy-table-column>
                <jy-table-column v-if="columnProps.includes('plateNum')" label="车牌号" prop="plateNum"  min-width="150"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('driverNa')" label="司机" prop="driverNa" min-width="130"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('phone')" label="联系电话" prop="phone" min-width="130"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('status')" label="状态" min-width="120">
                    <template v-slot="scope">{{ scope.row.status === "1" ? "运输完成" : "运输中" }}</template>
                </jy-table-column>
                <jy-table-column v-if="columnProps.includes('gpsNo')" label="GPS编号" prop="gpsNo"  min-width="120"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('cUserNa')" label="制单人" prop="cUserNa" min-width="140"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('rUserNa') && current === '1'" label="回执登记人" prop="rUserNa" min-width="120"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('remark')" label="备注" prop="remark"  min-width="200"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <t-fixed @emerge="getColumns" ref="tFixed" tagTitle="label" :banList="['name', 'orgName']"></t-fixed>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import TFixed from "@/components/pages/admin/common/TFixed/tFixed.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        created() {
            this.setOperateList();
        },
        mounted() {
            this.getList();

            const columns = this.$refs.tFixed.showList;
            this.columnProps = columns.length ? columns : this.columns.map(v => v.prop);
        },
        mixins: [btnMixins],
        methods: {
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/deal/queryPage", {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        ...this.form,
                        dealTStart: this.form.createT && this.form.createT[0],
                        dealTEnd: this.form.createT && this.form.createT[1],
                        status: this.current,
                        createT: undefined
                    })
                    .then(res => {
                        this.tableData = res.detail.list;
                        this.tableData.forEach(v => {
                            const w = this.wasteTypes.find(w => w.wasteTId === v.wasteT);
                            this.$set(v, "unit", w.unit);
                            this.$set(v, "suttleW", this.formatSuttle(v.grossW, v.tareW));
                        });
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getColumns() {},
            showDefineTable() {
                this.$refs.tFixed.init(this.columns);
            },
            add() {
                this.$emit("switch");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            handleDelete(row) {
                this.$confirm("是否删除该处置单?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/deal/delete";
                        this.$http.post(url, row.dealId).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            formatWast(id) {
                const w = this.wasteTypes.find(v => v.wasteTId === id);
                if (w) {
                    return w.cName;
                }
                return "-";
            },
            formatUnit(key) {
                const u = this.dUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatHUnit(key) {
                const u = this.hUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatSuttle(t, p) {
                if (isNaN(t) || isNaN(p)) {
                    return "-";
                }
                return t - p;
            },
            formatDeviationValue(grossW, tareW, dGrossW, dTareW) {
                if (isNaN(grossW) || isNaN(tareW) || isNaN(dGrossW) || isNaN(dTareW)) {
                    return 0;
                } else {
                    const d1 = grossW - tareW;
                    const d2 = dGrossW - dTareW;
                    return ((Math.abs(d1 - d2) / d2) * 100).toFixed(2) + "%";
                }
            },
            handleRegister(row) {
                this.$emit("switch", {
                    ...row,
                    register: true
                });
            },
            handleEdit(row) {
                this.$emit("switch", {
                    ...row,
                    edit: true
                });
            },
            handleView(row) {
                this.$emit("switch", {
                    ...row,
                    view: true,
                    register: true
                });
            },
            setOperateList() {
                let l = [
                    {
                        name: "回执登记",
                        icon: "el-icon-edit-outline",
                        fun: this.handleRegister,
                        isShow: () => {
                            return this.current === "0" && this.btnexist("wastesHandleCenterReceipt");
                        }
                    },
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesHandleCenterView");
                        }
                    },
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleEdit,
                        isShow: () => {
                            return this.btnexist("wastesHandleCenterUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesHandleCenterDelete");
                        }
                    }
                ];
                this.operateList = l;
            }
        },
        computed: {
            dUnitList() {
                return this.$parent.dUnitList;
            },
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            hUnitList() {
                return this.$parent.hUnitList;
            },
            handleTypes() {
                return this.$parent.handleTypes;
            },
            countList() {
                return this.$parent.countList;
            }
        },
        data() {
            return {
                current: "0",
                loading: false,
                tableData: [],
                total: 0,

                pageIndex: 1,
                pageSize: 10,

                showProps: [],
                columnProps: [],
                columns: [
                    { label: "单据编号", prop: "receiptNo" },
                    { label: "处置出厂日期", prop: "dealT" },
                    { label: "出库分拣中心", prop: "unitId" },
                    { label: "固废类别", prop: "wasteT" },
                    { label: "处置单位", prop: "dUnitId" },
                    { label: "处置方式", prop: "delway" },
                    { label: "出厂毛重", prop: "grossW" },
                    { label: "出厂皮重", prop: "tareW" },
                    { label: "出厂净重", prop: "suttleW" },
                    { label: "处置过磅净重", prop: "sum" },
                    { label: "偏差值", prop: "deviation" },
                    { label: "车牌号", prop: "plateNum" },
                    { label: "司机", prop: "driverNa" },
                    { label: "联系电话", prop: "phone" },
                    { label: "状态", prop: "status" },
                    { label: "GPS编号", prop: "gpsNo" },
                    { label: "制单人", prop: "cUserNa" },
                    { label: "回执登记人", prop: "rUserNa" },
                    { label: "备注", prop: "remark" },
                ],

                form: {
                    receiptNo: "",
                    unitId: "",
                    wasteT: "",
                    dUnitId: "",
                    delway: "",
                    plateNum: "",
                    driverNa: "",
                    gpsNo: "",
                    cUserNa: "",
                    createT: [],
                    rUserNa: ""
                },
                operateList: [],

                btnMenuId: "wastesHandleCenter"
            };
        },
        components: {
            ExportExcel,
            TFixed
        }
    };
</script>
<style scoped>
    .page_header {
        margin-bottom: 15px;
    }
</style>
